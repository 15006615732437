import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"privacy-policy"} />
		<Helmet>
			<title>
				AceTennis – Kortunuzu Bugün Rezerve Edin
			</title>
			<meta name={"description"} content={"AceTennis'te en kaliteli tenis kortu kiralama hizmetlerini keşfedin. Kortlarımız maçlar, antrenmanlar ve dostluk oyunları için mükemmeldir. Şimdi rezervasyon yaparak üstün bir tenis deneyimi yaşayın!"} />
			<meta property={"og:title"} content={"AceTennis – Kortunuzu Bugün Rezerve Edin"} />
			<meta property={"og:description"} content={"AceTennis'te en kaliteli tenis kortu kiralama hizmetlerini keşfedin. Kortlarımız maçlar, antrenmanlar ve dostluk oyunları için mükemmeldir. Şimdi rezervasyon yaparak üstün bir tenis deneyimi yaşayın!"} />
			<link rel={"shortcut icon"} href={"https://glowesbair.org/img/5738682.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://glowesbair.org/img/5738682.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://glowesbair.org/img/5738682.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://glowesbair.org/img/5738682.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://glowesbair.org/img/5738682.png"} />
			<meta name={"msapplication-TileImage"} content={"https://glowesbair.org/img/5738682.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0">
			<Box align-items="center" display="flex" justify-content="center" flex-direction="column">
				<Text
					margin="0px 0px 0px 0px"
					font="--headline1"
					color="--dark"
					sm-font="normal 700 52px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
				>
					Gizlilik Politikası
				</Text>
				<Text
					margin="25px 0px 0px 0px"
					font="--lead"
					display="block"
					text-align="left"
					color="--darkL2"
					lg-width="100%"
				>
					Bu Gizlilik Politikası ("Politika"), glowesbair.org ("biz", "bize" veya "bizim") web sitesi glowesbair.org ve ilgili hizmetlerin ("Hizmetler") ziyaretçileri ve kullanıcıları ("siz" veya "sizin") kişisel verilerini nasıl topladığını, kullandığını, aktardığını ve koruduğunu açıklar.
					<br />
					<br />
					Hizmetlerimizi kullanarak, bu Politikada açıklanan uygulamaları kabul edersiniz. Bu Politikayı kabul etmiyorsanız, lütfen Hizmetlerimizi kullanmayın.
					<br />
					<br />
					1. Topladığımız Bilgiler
					<br />
					<br />
					Aşağıdaki tür bilgileri toplayabiliriz:
					<br />
					<br />
					1.1 Kişisel Veriler: Adınız, e-posta adresiniz, posta adresiniz ve Hizmetlerimizle etkileşimde bulunurken gönüllü olarak verdiğiniz diğer bilgiler gibi kişisel verileri toplayabiliriz.
					<br />
					<br />
					1.2 Kullanım Bilgileri: IP adresiniz, tarayıcı türünüz, işletim sisteminiz ve ziyaret ettiğiniz sayfalar gibi Hizmetlerimizle etkileşimleriniz hakkında kişisel olmayan bilgileri toplayabiliriz. Ayrıca bu bilgileri toplamak için çerezler ve benzeri teknolojiler kullanabiliriz.
					<br />
					<br />
					2. Bilgilerinizi Nasıl Kullanıyoruz
					<br />
					<br />
					Topladığımız bilgileri şu amaçlarla kullanıyoruz:
					<br />
					<br />
					2.1 Hizmet Sağlama ve İyileştirme: Taleplerinizi ve isteklerinizi yanıtlamak dahil olmak üzere Hizmetlerimizi sağlamak, sürdürmek ve iyileştirmek için bilgilerinizi kullanıyoruz.
					<br />
					<br />
					2.2 Analiz: Kullanıcı eğilimlerini ve tercihlerini analiz etmek ve Hizmetlerimizi iyileştirmek için analiz araçları kullanıyoruz.
					<br />
					<br />
					3. Bilgilerinizi Paylaşma
					<br />
					<br />
					Kişisel bilgilerinizi üçüncü şahıslara satmıyor, kiralamıyor veya ticaretini yapmıyoruz. Ancak, aşağıdaki durumlarda bilgilerinizi paylaşabiliriz:
					<br />
					<br />
					3.1 Yasal Gereklilikler: Kanun, yönetmelik, mahkeme emri veya hükümet talebi gerektirdiğinde bilgilerinizi ifşa edebiliriz.
					<br />
					<br />
					3.2 Hakların Korunması: Haklarımızı, gizliliğimizi, güvenliğimizi veya mülkiyetimizi korumak veya herhangi bir kişinin güvenliğini tehdit eden bir acil duruma yanıt vermek gerektiğinde bilgilerinizi ifşa edebiliriz.
					<br />
					<br />
					4. Seçimleriniz
					<br />
					<br />
					Kişisel bilgilerinizle ilgili olarak aşağıdaki seçeneklere sahipsiniz:
					<br />
					<br />
					4.1 Erişim ve Düzeltme: Kişisel bilgilerinize erişmek ve bunları düzeltmek için bizimle contact@glowesbair.org adresinden iletişime geçebilirsiniz.
					<br />
					<br />
					4.2 Reddetme: Bizden reklam e-postaları almayı durdurmak için bu e-postalardaki talimatları izleyebilirsiniz.
					<br />
					<br />
					5. Güvenlik
					<br />
					<br />
					Bilgilerinizi korumak için makul idari, teknik ve fiziksel önlemler kullanıyoruz. Ancak, internet üzerinden veri aktarımı veya elektronik depolama yöntemi tamamen güvenli değildir ve mutlak güvenliği garanti edemeyiz.
					<br />
					<br />
					6. Çocukların Gizliliği
					<br />
					<br />
					Hizmetlerimiz 13 yaşından küçük kişilere yönelik değildir. 13 yaşından küçük bir çocuktan bilgi topladığımızı düşünüyorsanız, lütfen bizimle iletişime geçin, bu bilgileri derhal sileceğiz.
					<br />
					<br />
					7. Gizlilik Politikasındaki Değişiklikler
					<br />
					<br />
					Bu Gizlilik Politikasını zaman zaman güncelleyebiliriz. Veri işleme yöntemlerimiz hakkında bilgi sahibi olmak için bu Politikayı periyodik olarak gözden geçirmenizi öneririz.
					<br />
					<br />
					8. Bize Ulaşın
					<br />
					<br />
					Bu Gizlilik Politikası veya veri işleme yöntemlerimiz hakkında herhangi bir sorunuz, endişeniz veya isteğiniz varsa, {" "}
					<Link href="/contacts">
						bize ulaşın
					</Link>
					.
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65201646cd81ee001f7ce215"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});